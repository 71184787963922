@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

/* INDEX ITEM */

.html {
    font-family: "Roboto Condensed", sans-serif;
}
*{
    box-sizing: border-box;
}

ol {
    list-style-type: decimal-leading-zero;
}
