@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#blockColorblindContent {
    display: none;
}

/* INDEX ITEM */

.html {
    font-family: "Roboto Condensed", sans-serif;
}
*{
    box-sizing: border-box;
}

ol {
    list-style-type: decimal-leading-zero;
}

